<template>
  <div class="subscribe_layout">
    <div class="content-container">
      <div v-if="lang === 'en'" class="terms-content">
        <strong>PRIVACY POLICY</strong>
        <br />
        <p style="margin-bottom: 10px">
          Your privacy is very important to us. At HighFit, we are committed to
          protecting the confidentiality and security of your personal
          information. This Policy outlines how we collect, use, and safeguard
          your data to provide you with our fitness services.
        </p>

        <ul style="line-height: 20px">
          <li>
            <strong>Purpose of Data Collection:</strong>
            <br />
            <p>
              Before or at the time of collecting personal information, we will
              identify the purposes for which the information is being
              collected.
            </p>
          </li>

          <li>
            <strong>Use of Personal Information:</strong>
            <br />
            <p>
              We will collect and use personal information solely to deliver our
              services, including personalized diet tips and fitness content,
              and for other compatible purposes, unless we obtain your consent
              or as required by law.
            </p>
          </li>

          <li>
            <strong>Retention of Data:</strong>
            <br />
            <p>
              We will only retain personal information as long as necessary to
              fulfill the purposes for which it was collected.
            </p>
          </li>

          <li>
            <strong>Method of Collection:</strong>
            <br />
            <p>
              Personal information will be collected by lawful and fair means
              and, where appropriate, with your knowledge or consent.
            </p>
          </li>

          <li>
            <strong>Accuracy of Data:</strong>
            <br />
            <p>
              Personal data should be relevant to the purposes for which it is
              used and, to the extent necessary, kept accurate, complete, and
              up-to-date.
            </p>
          </li>

          <li>
            <strong>Security Measures:</strong>
            <br />
            <p>
              We will protect personal information through reasonable security
              safeguards against loss, theft, unauthorized access, disclosure,
              copying, use, or modification.
            </p>
          </li>

          <li>
            <strong>Transparency:</strong>
            <br />
            <p>
              We will make readily available to customers information about our
              policies and practices relating to the management of personal
              information.
            </p>
          </li>
        </ul>

        <p>
          We are dedicated to conducting our business in accordance with these
          principles to ensure that your privacy is protected and maintained.
        </p>
      </div>
      <div
        style="direction: rtl; text-align: start"
        class="terms-content"
        v-else
      >
        <strong>سياسة الخصوصية</strong>
        <br />
        <p style="margin-bottom: 10px">
          خصوصيتك مهمة جدًا بالنسبة لنا. في HighFit، نحن ملتزمون بحماية سرية
          وأمان معلوماتك الشخصية. توضح هذه السياسة كيفية جمع واستخدام وحماية
          بياناتك لتقديم خدمات اللياقة البدنية.
        </p>

        <ul style="line-height: 20px">
          <li>
            <strong>الغرض من جمع البيانات:</strong>
            <br />
            <p>
              قبل أو أثناء جمع المعلومات الشخصية، سنحدد الأغراض التي يتم جمع هذه
              المعلومات من أجلها.
            </p>
          </li>

          <li>
            <strong>استخدام المعلومات الشخصية:</strong>
            <br />
            <p>
              سنقوم بجمع واستخدام المعلومات الشخصية فقط لتقديم خدماتنا، بما في
              ذلك النصائح الغذائية المخصصة ومحتوى اللياقة البدنية، ولأغراض أخرى
              متوافقة، إلا إذا حصلنا على موافقتك أو كما يقتضي القانون
            </p>
          </li>

          <li>
            <strong>الاحتفاظ بالبيانات:</strong>
            <br />
            <p>
              سنحتفظ بالمعلومات الشخصية فقط طالما كان ذلك ضروريًا لتحقيق الأغراض
              التي تم جمعها من أجلها.
            </p>
          </li>

          <li>
            <strong>طريقة جمع البيانات:</strong>
            <br />
            <p>
              سيتم جمع المعلومات الشخصية بوسائل قانونية وعادلة، وعند الاقتضاء،
              بموافقتك أو علمك.
            </p>
          </li>

          <li>
            <strong>دقة البيانات:</strong>
            <br />
            <p>
              يجب أن تكون البيانات الشخصية ذات صلة بالأغراض التي تُستخدم من
              أجلها، وأن تُحفظ دقيقة وكاملة ومحدثة إلى الحد الضروري.
            </p>
          </li>

          <li>
            <strong>تدابير الأمان:</strong>
            <br />
            <p>
              سنحمي المعلومات الشخصية من خلال تدابير أمان معقولة ضد الفقدان أو
              السرقة أو الوصول غير المصرح به أو الإفصاح أو النسخ أو الاستخدام أو
              التعديل.
            </p>
          </li>

          <li>
            <strong>الشفافية:</strong>
            <br />
            <p>
              سنُتيح للعملاء بسهولة المعلومات المتعلقة بسياساتنا وممارساتنا
              الخاصة بإدارة المعلومات الشخصية.
            </p>
          </li>
        </ul>

        <p>
          نحن ملتزمون بإدارة أعمالنا وفقًا لهذه المبادئ لضمان حماية خصوصيتك
          والحفاظ عليها.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',

  data () {
    return {
      lang: localStorage.getItem('lang') ?? 'ar'
    }
  },
  methods: {
    showKurdish () {
      const countryCode = localStorage.getItem('countryCode')
      return countryCode
    }
  },

  mounted () {
    if (this.showKurdish() === '964') {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.subscribe_layout {
  background-image: url('../../assets/images/subscribe/subscribe_desk_bg.png');
  min-height: 100vh;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    background-image: url('../../assets/images/subscribe/bg_mobile.png');
  }
}

.content-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  max-width: 600px;
  overflow-y: scroll;
  background-color: #f9f9f9;
  border-radius: 8px;
  margin: 10px;
}

.terms-content {
  font-size: 14px;
  color: #212529;
}
</style>
